<template>
  <div
    class="h-100vh"
    v-bind:style="{
      'background-image':
        'url(' + require('../../../assets/images/BG-Orang-Kerry-Club.jpg') + ')'
    }"
  >
    <div v-if="loginStep == 3" class="btn-logout p-3">
      <span class="pointer" @click="toggleLogout">
        <img
          src="@/assets/images/arrow-right-from-bracket.svg"
          alt="Kitten"
          class="logo-img"
        />
        {{ $t("logout") }}</span
      >
    </div>
    <div class="d-flex justify-content-center w-100 login-container">
      <div class="w-100 my-5">
        <div v-if="loginStep == 3">
          <Profile :profile="profileUser" />
        </div>
        <div v-else>
          <!-- <div class="logo-image-container text-center login-header mx-3">
        <img src="@/assets/images/kerry-logo.jpg" alt="Kitten" class="logo-img" />
        </div> -->
          <div
            class="pb-4 text-center text-white font-weight-bold f-size-22"
            v-if="activeLanguage == 'th'"
          >
            <div>{{ $t("msgWelcomeLogin") }}</div>
            <!-- <div>{{ $t("msgKerryExpressClub") }}</div> -->
          </div>
          <div
            class="pb-4 text-center text-white font-weight-bold f-size-22"
            v-else
          >
            <div>{{ $t("msgKerryExpressClub") }}</div>
          </div>
          <div class="d-flex justify-content-center">
            <div class="card p-3 mx-3 login-card">
              <div class="f-size-18 text-center font-weight-bold">
                {{ $t("login") }}
              </div>
              <div v-if="loginStep == 1">
                <div class="pt-4">
                  <b-alert
                    v-if="loginFailTimes == 5"
                    show
                    variant="danger"
                    class="mb-0 pl-2"
                    >{{ $t("messageLoginFailTimes1") }}{{ timeLeft
                    }}{{ $t("messageLoginFailTimes2") }}</b-alert
                  >
                  <InputText
                    class="my-2 login-input"
                    v-model="form.mobileNo"
                    textFloat=""
                    :placeholder="$t('telephoneNumber')"
                    @onKeypress="isNumber($event)"
                    type="tel"
                    name="mobileNo"
                    isRequired
                    :isValidate="$v.form.mobileNo.$error"
                    :v="$v.form.mobileNo"
                  />
                  <InputText
                    class="my-2 login-input"
                    v-model="form.password"
                    textFloat=""
                    :placeholder="$t('idCard')"
                    type="text"
                    name="password"
                    isRequired
                    @onKeypress="isNumber($event)"
                    :isValidate="$v.form.password.$error"
                    :v="$v.form.password"
                  />
                  <!-- isShowPassword           -->
                  <b-form-checkbox
                    id="checkbox-1"
                    name="checkbox-1"
                    v-model="consent"
                  >
                    <span>{{ $t("consentMessageLogin1") }}</span
                    ><a
                      href="https://th.kerryexpress.com/th/privacy-notice"
                      target="_blank"
                      >{{ $t("consentMessageLogin2") }}</a
                    ><span>{{ $t("consentMessageLogin3") }}</span>
                  </b-form-checkbox>
                </div>
                <div v-if="error != ''" class="m-2 text-center">
                  <span class="text-danger w-100">
                    {{ error }}
                    <br />
                    {{ " " }}
                  </span>
                </div>
                <div class="pt-2 pb-2">
                  <b-button
                    class="w-100 btn-main py-2"
                    :disabled="!consent || loginFailTimes == 5"
                    @click="checkForm"
                    >{{ $t("login2") }}
                    <b-spinner
                      class="align-middle w-1rem"
                      v-if="isLogin"
                    ></b-spinner
                  ></b-button>
                </div>
                <!-- <div class="py-4 text-center main-color f-size-14">
                  <u
                    class="pointer"
                    @click="
                      $router.push(
                        '/forget-password/' +
                          activeLanguage +
                          '?sessionId=' +
                          sessionId
                      )
                    "
                    >{{ $t("forgotPassword") }}</u
                  >
                </div> -->
                <!-- <div class="text-center pb-3">
                  <span>{{ $t("noAccount") }}</span>
                  <span
                    class="font-weight-bold pointer"
                    @click="
                      $router.push(
                        '/register/' + activeLanguage + '?sessionId=' + sessionId
                      )
                    "
                  >
                    {{ $t("registerNow") }}</span
                  >
                </div> -->
              </div>
              <div v-if="loginStep == 2">
                <LoginVerifyOtpPanel
                  :mobileNo="form.mobileNo"
                  :userGUID="form.userGUID"
                  v-on:handleNextStep="handleNextStep"
                  v-on:getUserProfile="getUserProfile"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalAlertError
      ref="modalAlertError"
      :text="$t('failLogin')"
      v-on:hadleModalAlertErrorClose="hadleModalAlertErrorClose"
    />

    <ModalConfirm
      ref="modalConfirm"
      :text="$t('msgLogout')"
      v-on:handleModalConfirm="logout"
    />
  </div>
</template>

<script>
import InputText from "@/components/input/InputText";
import ModalAlertError from "@/components/alert-modal/ModalAlertError";
import LoginVerifyOtpPanel from "./LoginVerifyOtpPanel.vue";
import Profile from "./Profile.vue";
import ModalConfirm from "./modal/ModalConfirm.vue";

import {
  required,
  minLength,
  maxLength,
  numeric
} from "vuelidate/lib/validators";

export default {
  components: {
    InputText,
    ModalAlertError,
    LoginVerifyOtpPanel,
    Profile,
    ModalConfirm
  },
  data() {
    return {
      form: {
        mobileNo: "",
        password: "",
        userGUID: ""
      },
      redirectUrl: "",
      isDisable: false,
      isLogin: false,
      sessionId: null,
      error: "",
      activeLanguage: "th",
      lineProfile: {
        userId: "",
        displayName: "",
        pictureUrl: "",
        statusMessage: ""
      },
      userData: null,
      consent: false,
      loginFailTimes: 0,
      modalMessage: "พบข้อผิดพลาด",
      loginStep: 1,
      profileUser: null,
      timeLeft: "00:00"
    };
  },
  validations() {
    return {
      form: {
        mobileNo: { required },
        password: {
          required,
          numeric
        }
        // minLength: minLength(6),
        // maxLength: maxLength(6),
      }
    };
  },
  watch: {
    "$route.fullPath": function () {
      if (this.$route.params.lang) {
        this.activeLanguage = this.$route.params.lang;
      }
    }
  },
  beforeCreate: async function () {
    if (this.$liff.isInClient()) {
      this.$liff
        .init({ liffId: this.$liff_Main_ID_Kerry_Friend })
        .then(() => {
          this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;
          });
        })
        .catch(error => {
          console.error("error", error);
        });
    }
  },
  created: async function () {
    this.sessionId = this.$route.query.sessionId;
    if (this.sessionId) {
      await this.getUserData();
    } else {
      this.$router.push("/session-expired");
    }
    if (this.$route.params.lang) {
      this.activeLanguage = this.$route.params.lang;
    }
  },
  methods: {
    getUserData: async function () {
      await this.$axios
        .get(this.$baseUrl + "/user/" + this.sessionId)
        .then(response => {
          if (response.data.result === 1) {
            if (response.data.detail.userGUID) {
              this.userData = response.data.detail;
              this.form.userGUID = response.data.detail.userGUID;
              if (
                response.data.detail.tierId &&
                response.data.detail.tierId != 7
              ) {
                // tierId > 0 -> profile else -> login
                this.getUserProfile();
                this.loginStep = 3;
              }
            } else {
              this.$router.push("/session-expired");
            }
          } else {
            this.$router.push("/session-expired");
          }
        });
    },
    getUserProfile: async function () {
      await this.$axios
        .get(
          this.$baseUrl + "/kerryapi/KerryFriend/profile/" + this.form.userGUID
        )
        .then(response => {
          if (response.data.result === 1) {
            this.profileUser = response.data.detail;
            // this.profileUser = {
            //   profilePictureURL : "https://popcornfor2.com/upload/115/news-full-114801.jpeg",
            //   memberId: "1700000002",
            //   mobileNumber: "0610000002",
            //   titleName: {
            //     en: "Mrs.",
            //     th: "นาง"
            //   },
            //   firstName: {
            //     en: null,
            //     th: "0610000002"
            //   },
            //   lastName: {
            //     en: null,
            //     th: "POC"
            //   },
            //   accountStatus: "NON_VERIFY",
            //   activeTier: "1"
            // }
          } else {
            this.$router.push("/session-expired");
          }
        });
    },
    checkForm: async function () {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;
      await this.login();
    },
    login: async function () {
      this.isDisable = true;
      this.isLogin = true;
      this.error = "";
      await this.$axios
        .post(`${this.$baseUrl}/kerryapi/KerryFriend/CheckRegistered`, {
          mobileNumber: this.form.mobileNo,
          idCard: this.form.password,
          userGuid: this.form.userGUID
        })
        .then(data => {
          // console.log(data.data)
          this.isDisable = false;
          this.isLogin = false;
          if (data.data.result == 1) {
            // this.isDisable = false;
            //       if (data.data.detail.isRegistered) {
            //         this.profileUser = data.data.detail;
            //         // this.profileUser = {
            //         //   profilePictureURL : "https://popcornfor2.com/upload/115/news-full-114801.jpeg",
            //         //   memberId: "1700000002",
            //         //   mobileNumber: "0610000002",
            //         //   titleName: {
            //         //     en: "Mrs.",
            //         //     th: "นาง"
            //         //   },
            //         //   firstName: {
            //         //     en: null,
            //         //     th: "0610000002"
            //         //   },
            //         //   lastName: {
            //         //     en: null,
            //         //     th: "POC"
            //         //   },
            //         //   accountStatus: "NON_VERIFY",
            //         //   activeTier: "1"
            //         // }
            //         this.loginStep = 3; // page profile
            //       } else {
            this.loginStep = 2; //  page otp
            //       }
            //       // this.checkBeforeCloseApp();
            this.loginFailTimes = 0;
            //     } else {
            //       this.loginFailTimes += 1;
            //       this.$refs.modalAlertError.show();

            //       if (this.loginFailTimes == 5) {
            //         this.onCountTime();
            //       }

            //       this.isLogin = false;
          } else {
            this.error = "พบข้อมูลไม่ถูกต้อง กรุณาใส่ ข้อมูลอีกครั้ง";
          }
        })
        .catch(err => {
          this.error = err.message;
          console.log(err);
        });

      // this.loginStep = 2; //  page otp
      // this.loginFailTimes = 0;
    },
    hadleModalAlertErrorClose() {
      this.$refs.modalAlertError.hide();
    },
    toggleLogout() {
      this.$refs.modalConfirm.show();
    },
    async logout() {
      await this.$axios
        .delete(
          `${this.$baseUrl}/kerryapi/KerryFriend/logout/` + this.form.userGUID
        )
        .then(data => {
          if (data.data.result == 1) {
            this.loginStep = 1; // page login
          } else {
            this.$refs.modalAlertError.show();
            this.isLogin = false;
            this.error = data.data.message;
          }
        });
      this.loginStep = 1;
    },
    onCloseLiffApp() {
      this.$liff.closeWindow();
    },
    checkBeforeCloseApp() {
      const queryStringEncode = encodeURIComponent(window.location.search);
      const queryStringDecode = decodeURIComponent(queryStringEncode);

      const params = new URLSearchParams(queryStringDecode);
      const redirectUrl = params.get("redirectUrl");

      if (redirectUrl) {
        this.$router.push(`${redirectUrl}`);
      } else {
        if (this.$liff.isInClient()) {
          this.onCloseLiffApp();
        } else {
          setTimeout(() => {
            window.close();
          }, 1000);
          //this.$router.push(`/success/login`);
        }
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    handleNextStep(step, profileUser) {
      if (profileUser) {
        this.profileUser = profileUser;
      }
      this.loginStep = step;
    },
    onCountTime() {
      let countDownDate = new Date();
      countDownDate.setSeconds(countDownDate.getSeconds() + 300);
      countDownDate = countDownDate.getTime();
      let timerWait = setInterval(() => {
        let now = new Date().getTime();
        let distance = countDownDate - now;
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        if (seconds < 10) {
          seconds = "0" + seconds;
        }
        this.timeLeft = minutes + ":" + seconds + "";

        if (distance < 0) {
          clearInterval(timerWait);
          this.timeLeft = "00:00";
          this.loginFailTimes = 0;
          return;
        }
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
.logo-img {
  width: 100px;
  height: auto;
  text-align: center;
}
.login-header {
  height: 60px;
  border-bottom: 2px solid white;
}
.login-container {
  height: calc(100vh - 60.5px);
}
.login-card {
  width: 100%;
  max-width: 450px;
}
::v-deep .card {
  border-color: white;
  border-radius: 14px;
}

.w-1rem {
  width: 1rem;
  height: 1rem;
}

.button-eye {
  position: absolute;
  right: 10px;
  top: 0.8rem;
  z-index: 6;
}
.btn-logout {
  position: absolute;
  right: 0;
  color: white;
}

.logo-img {
  width: 20px;
  height: auto;
  text-align: center;
}
@media only screen and (max-width: 767.98px) {
  .login-card {
    width: 100%;
  }
  .btn-logout {
    margin-top: 2rem;
  }
  .login-container {
    margin-top: 2rem;
  }
}
</style>