<template>
    <div>
        <div
          class="pb-3 text-center text-white font-weight-bold f-size-22"
        >
        <div class="w-100 text-right">
          <span class="pointer txt-lang" @click="toggleChangeLang">
            TH/EN </span>
          </div>
          <div>{{ $t("msgWelcomeLoginSuccess") }}</div>
        </div>
        <div class="content-stack">
        <div class="mx-3 image-stack">
            <img :src="profile.profilePictureURL || profile.imageUrl"
              class="logo-img" />
        </div>
        <div class="d-flex justify-content-center">
          <div class="card mx-3">
            <div class="line-profile">
              <span class="title-profile">{{ $t("profileCodeMember") }}</span><br />
              <span>{{profile.memberId}}</span>
            </div>
            <div class="line-profile">
              <span class="title-profile">{{ $t("profileFirstName") }}</span><br />
              <span>{{activeLanguage == "en" ? profile.titleName.en : profile.titleName.th }}
                {{activeLanguage == "en" ? profile.firstName.en : profile.firstName.th }}</span>
            </div>

            <div class="line-profile">
              <span class="title-profile">{{ $t("profileLastName") }}</span><br />
              <span>{{activeLanguage == "en" ? profile.lastName.en : profile.lastName.th }}</span>
            </div>

            <div class="line-profile">
              <span class="title-profile">{{ $t("profilePhoneNumber") }}</span><br />
              <span>{{profile.mobileNumber}}</span>
            </div>

            <div class="line-profile">
              <span class="title-profile">{{ $t("profileTierMember") }}</span><br />
              <span>Level {{profile.activeTier}}</span>
            </div>
          </div>
        </div>
        </div>
    </div>
        
</template>

<script>
export default {
   props: {
    profile: {
      required: false,
      type: Object
    },
   },
   created() {
    this.activeLanguage = this.$route.params.lang || "th";
   },
  data() {
    return {
      activeLanguage: "th",
    }
  },
  methods: {
    toggleChangeLang() {
      this.activeLanguage = this.activeLanguage == "th" ? "en" : "th";
      let sessionId = this.$route.query.sessionId;
      this.$router.push(
                '/login/kerryfriend/' + this.activeLanguage + '?sessionId=' + sessionId
              )      
    }
  }
}
</script>

<style scoped>
::v-deep .card {
  border-color: white;
  border-radius: 14px;
  width: 100%;
  padding: 5rem 1rem 1rem 1rem;
}
.content-stack {
  position: relative;
  width: 100%;
  margin-top: 80px;
}
.image-stack {
    position: absolute;
    left: 0;
    right: 0;
    top: -5rem;
    text-align: center;   
    z-index: 1;
}

.logo-img {
  width: 150px;
  height: auto;
  text-align: center;
  border-radius: 50%;
  border: 6px solid #F37021;
}
.line-profile {
  border-bottom: 1px solid #bcbcbc;
  margin-bottom: 1rem;
}
.title-profile {
  font-size: 14px;
  color: #bebebe;
}
.txt-lang {
  font-weight: normal;
  font-size: 16px;
  margin-right: 2rem;
  margin-top: 2rem;
}

@media only screen and (min-width: 767.98px) {
  .card {
    max-width: 450px;
  }
}
</style>