<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="confirmModal"
      hide-header
      hide-footer
      centered
    >
      <div class="text-center">
        <p class="text-text" v-if="text">{{ text }}</p>
        <div class="px-2 pb-2">
             <b-button
                class="w-100 btn-main mb-2"
                @click="handleModalConfirm"
                >{{ $t("submit2") }}
            </b-button>
            <b-button
                class="w-100 btn-secondary"
                @click="hide"
                >{{ $t("cancel") }}
            </b-button>
        </div>
       
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      showModal: false
    };
  },
  methods: {
    handleModalConfirm() {
        this.hide();
        this.$emit("handleModalConfirm");
    },
    show() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    }
  }
};
</script>

<style scoped>
.modal-header {
  border: 0 !important;
  padding: 0;
}

.text-text {
  color: #16274a;
  margin: 3rem;
  font-weight: bold;

}

.img {
  width: 100px;
}

.modal-body {
  margin: auto;
  text-align: center;
}
</style>
