<template>
   <div>
        <b-alert v-if="sendFail" show variant="danger" class="mb-2" >{{ $t("failSendOTP") }}</b-alert>
  
  
        <div class="text-center mt-2">
            <span class="text-black-50">.{{ $t('EnterOTPLogin') }} {{ mobileNo }}</span><br />
            <span class="text-black-50">{{ $t('otpRef') }}: {{ formOTP.otpReference }}</span>
        </div>
        
        <InputText
           class="my-2"
            v-model="formOTP.Otp"
            textFloat=""
            :placeholder="$t('pleaseEnterOTPLogin')"
            @onKeypress="isNumber($event)"
            type="tel"
            name="otp"
            :isValidate="$v.formOTP.Otp.$error"
            :v="$v.formOTP.Otp"
        />
        <div v-if="!isLoading" class="text-center main-color">
            <u v-if="timeLeft == 'EXPIRED'" @click="handleClickResend(2)"
              class="pointer mb-4"
              >{{ $t("sendOTPAgain") }}</u
            >
            <p class="mb-4" v-else>{{ $t("msgSendAgain") }}: ({{ timeLeft }} {{$t("minute")}}) </p>
        </div>
        <div>
          <b-button
            class="w-100 btn-main mb-2"
            @click="submitValidateOTP"
            :disabled="isLoading"
            >{{ $t("next") }}</b-button
          >
          <b-button
            class="w-100 btn-secondary"
            @click="handleCancel"
            >{{ $t("cancel") }}</b-button
          >
        </div>
    </div>
</template>

<script>
import InputText from "@/components/input/InputText";
import { minLength, requiredIf, integer } from "vuelidate/lib/validators";

export default {
    components: {
       InputText 
    },
    props: {
        mobileNo: {
            required: true,
            type: String
        },
        userGUID: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            formOTP: {
                otpToken: "",
                otpReference: "",
                Otp: ""
            },
            timeLeft: "00:00",
            sendFail: false,
            isLoading: false,
            errors: "",
            profileUser: {}
        }
    },
    created: async function () {
        await this.sendRequestOTP();
    },
    methods: {
        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        sendRequestOTP: async function () {
            this.timeLeft = "00:00";
            this.errors = "";
            this.isLoading = true;
            await this.$axios
                .post(`${this.$baseUrl}/kerryapi/KerryFriend/RequestOTP`, {
                    mobileNumber : this.mobileNo,   //"0610000002"
	                functionName : "Register",
                    UserGUID: this.userGUID,
                })
                .then(async data => {
                this.isLoading = false;
                if (data.data.result == 1) {
                    this.isValidateOTP = true;
                    this.formOTP.otpReference = data.data.detail.otpReference;
                    this.formOTP.otpToken = data.data.detail.otpToken;
                    this.onCountTime();
                } else {
                    this.errors = data.message;
                }
            });
        },
        handleClickResend(flag) {
            this.formOTP = {
                otpToken: "",
                otpReference: "",
                Otp: ""
            }
            this.timeLeft = "00:00";
            if (flag == 1) {
                this.isValidateOTP = false;
            } else {
                this.sendRequestOTP();
            }
        },
        onCountTime() 
        {
             let countDownDate = new Date();
             countDownDate.setSeconds(countDownDate.getSeconds() + 180);
             countDownDate = countDownDate.getTime();
             let timerWait = setInterval(() => {
                 let now = new Date().getTime();

                 let distance = countDownDate - now;

                 let days = Math.floor(distance / (1000 * 60 * 60 * 24));
                 let hours = Math.floor(
                 (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                 );
                 let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                 let seconds = Math.floor((distance % (1000 * 60)) / 1000);

                 if (minutes < 10) {
                 minutes = "0" + minutes;
                 }
                 if (seconds < 10) {
                 seconds = "0" + seconds;
                 }
                 this.timeLeft = minutes + ":" + seconds + "";

                 if (distance < 0) {
                 clearInterval(timerWait);
                 this.timeLeft = "EXPIRED";
                 return;
                 }
             }, 1000);
        },
        submitValidateOTP: async function () {
            this.sendFail = false;
            this.$v.formOTP.$touch();
            if (this.$v.formOTP.$error) return;
                this.$v.formOTP.$reset();
                this.errors = "";
                this.isLoading = true;
                this.checkOTP();
        },
        checkOTP: async function () {
            this.formOTP = { ...this.formOTP, UserGUID: this.userGUID, MobileNumber: this.mobileNo }
            await this.$axios
                .post(`${this.$baseUrl}/kerryapi/KerryFriend/CheckOTP`, this.formOTP)
                .then(async data => {
                    this.isLoading = false;
                if (data.data.result == 1) {
                    if (data.data.detail.isValidOtp) {
                        this.$emit("handleNextStep", 3);
                        this.$emit("getUserProfile");
                    } else {
                        this.sendFail = true;
                    }
                } else {
                   this.sendFail = true;
                }
            });  
        },
        handleCancel() {
            this.$emit("handleNextStep", 1);
        },
    },
    validations() {
        return {
        formOTP: {
            Otp: {
            minLength: minLength(6),
            integer,
            required: requiredIf(function () {
                return this.isValidateOTP;
            })
            }
        }
        };
  },

}
</script>

<style>

</style>